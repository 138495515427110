import React, {useContext, useEffect, useState} from 'react'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import {Card, Col, Container, Nav, Navbar, NavDropdown, Row, Accordion} from 'react-bootstrap'
import db from './FirebaseDB'
import fileDb from './FileDb'
import {IRouteData} from "./IRouteData"
import {ICourseData} from "./ICourseData"
import IScheduleEntry from "./IScheduleEntry"
import ILecture from "./ILecture"
import IHeaderEntry from "./IHeaderEntry"
import IButtonInfo from "./IButtonInfo"
import {Navigate, useParams} from 'react-router-dom'
import { Helmet } from 'react-helmet'
import './style.css'
import {ExternalLinks} from "./ExternalLinks"
import Modal from 'react-bootstrap/Modal'
import {AppContext} from "./App";
import firebase from "firebase/compat/app";
// Required for side-effects
import "firebase/firestore";

function VdoCipherDialogue(props: any) {
    const {title, link, otp, playbackInfo} = props;
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            [<Button style={{padding: 0}} variant="link" onClick={handleShow}>{link}</Button>]
            <Modal  size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body><VdoCipherVideoViewer otp={otp} playbackInfo={playbackInfo} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        關閉
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
function VimeoVideoDialogue(props: any) {
    const {title, videoId, link} = props;
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
             [<Button style={{padding: 0}} variant="link" onClick={handleShow}>{link}</Button>]
            <Modal  size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body><VideoViewer videoId={videoId} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        關閉
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

interface INavbarProps {
    token: string
    logo: string
    title: string
    type?: string
    isCategoryDisabled: boolean
    schedule: IScheduleEntry[]
}

interface IHeaderProps {
    header: IHeaderEntry[];
    type?: string;
}


interface IVdoCipherVideoViewerProps {
    otp: string
    playbackInfo: string
}

const VdoCipherVideoViewer = (props: IVdoCipherVideoViewerProps) => {
    return (
        <iframe title="影片播放"
                src={
                    "https://player.vdocipher.com/playerAssets/1.x/vdo/embed/index.html#otp=" +
                    props.otp +
                    "&playbackInfo=" +
                    props.playbackInfo
                }
                style={{
                    border: 0,
                    height: "360px",
                    width: "640px",
                    maxWidth: "100%"
                }}
                allowFullScreen
                allow="encrypted-media">

        </iframe>
    )
}

interface IVideoViewerProps {
    videoId: string
}

const VideoViewer = (props: IVideoViewerProps) => {
    const {videoId} = props
    return (
        <iframe
            title="影片播放"
            src={"https://player.vimeo.com/video/" + videoId}
            width="640"
            height="360"
            frameBorder="0"
            allow="autoplay; fullscreen" allowFullScreen>
        </iframe>)
}

const RouteData = (
    props: {
        token?: string
        children: (props: IRouteData) => JSX.Element
    }) => {

    const [lastToken, setLastToken] = useState<string | null>(null)

    const [data, setData] = useState<IRouteData | null>(null)
    const [isLoading, setLoading] = useState<boolean>(false)
    const [isDirect, setDirect] = useState<boolean>(false)

    if (lastToken !== props.token && isLoading) {
        setLoading(false)
        setData(null)
    }

    useEffect(() => {
        if (!isLoading) {
            setLoading(true)
            const getData = async() => {
                if (props.token) {
                    setLastToken(props.token)
                    let result : IRouteData | null = await fileDb.get(props.token)
                    if (result === null) {
                        result = await db.get(props.token)
                    }
                    if (result !== null) {
                        setData(result)
                    } else {
                        setDirect(true)
                    }
                }
            }
            getData().then(() => {})
        }
    }, [props.token, data, isLoading])

    if (isDirect) {
        return <Navigate to="/"/>
    }

    if (data == null)
        return <div className="lds-roller">
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
        </div>

    return props.children(data)
}

function ParseLectureTitle(lecture : ILecture, token: string) {
    const id = lecture.title
    if (lecture.isStrong) {
        return (<h4 id={id} className="my-0 py-3 py-sm-1">{lecture.title}</h4>)
    } else if (lecture.isWarning) {
        return (<h4 id={id} className="my-0 py-3 py-sm-1" style={{color: "red"}}>{lecture.title}</h4>)
    } else if (lecture.isSmall) {
        return (<h4 id={id} className="my-0 px-1 py-3 py-sm-1" style={{fontWeight: "normal", fontSize: "0.85em", color: "#505050"}}>{lecture.title}</h4>)
    } else {
        return lecture.title
    }
}

function ParseContent(entry: IHeaderEntry) {
    if (entry.url) {
        return (<a
            href={entry.url}
            target="_blank"
            rel="noopener noreferrer"
        >
            {entry.content}
        </a>)
    } else {
        return entry.content;
    }
}

function CreateCategory(category: string)
{
    return (
        <Col
            sm={{span: 10, offset: 1}}
            md={{span: 10, offset: 1}}
            lg={{span:  2, offset: 0}}
            className="my-lg-auto mt-2 text-center py-2"
        >
            <h4 className="pl-1 py-0 my-0">{category}</h4>
        </Col>)
}


function MyNavbar(props: INavbarProps) {
    const strongLectures: string[] = [];

    props.schedule.map(
        (entry: IScheduleEntry) => entry.lectures.filter((lecture: ILecture) => lecture.isStrong)
            .map((e) => strongLectures.push(e.title)));

    if (props.type === "collapsed") {
    return (
        <Navbar expand={true} bg="light" className="py-0 pl-0">

            <Navbar.Toggle aria-controls="responsive-navbar-nav"  className="m-2"/>
            <Navbar.Collapse id="responsive-navbar-nav" className="ml-2">
                <Nav className="mr-auto" id="myNav">
                    <NavDropdown id="lecture-titles" title="章節">
                        {
                props.schedule.map(
                    (entry, i) =>
                        <NavDropdown.Item
                            key={'dropdown-category-item-' + i}
                            href={'/' + props.token + '#' + entry.category!}>
                            {entry.category}
                        </NavDropdown.Item>)
                        }
                    </NavDropdown>
                </Nav>
            </Navbar.Collapse>
        </Navbar>)
    }
    const categoryList = props.isCategoryDisabled ? "" : (
        <NavDropdown id="lecture-dates" title="上課日期">
            {
                props.schedule.map(
                    (entry, i) =>
                        <NavDropdown.Item
                            key={'dropdown-category-item-' + i}
                            href={'/' + props.token + '#' + entry.category!}>
                            {entry.category}
                        </NavDropdown.Item>)
            }
        </NavDropdown>)

    return (
        <Navbar expand={true} bg="light" className="py-0 pl-0">

            <Navbar.Toggle aria-controls="responsive-navbar-nav"  className="m-2"/>
            <Navbar.Collapse id="responsive-navbar-nav" className="ml-2">
                <Nav className="mr-auto" id="myNav">
                    {categoryList}
                    <NavDropdown id="lecture-titles" title="章節">
                        {
                            strongLectures.map(
                                (lecture, i) => (
                                    <NavDropdown.Item
                                        key={'dropdown-lecture-item-' + i}
                                        href={'/' + props.token + '#' + lecture}>
                                        {lecture}
                                    </NavDropdown.Item>)
                            )
                        }
                    </NavDropdown>
                </Nav>
            </Navbar.Collapse>
        </Navbar>)
}



function MyHeader(props : IHeaderProps) {
    if (props.type === "collapsed") {
        return
    }
    return (
        <Card className="mb-1 pt-1 border-dark">
            <Row>
                {
                    props.header.map((entry) => (
                        <React.Fragment key={entry.title}>
                            <Col xs={3} lg={2} className="p-1 text-center">
                                <h4>{entry.title}</h4>
                            </Col>
                            <Col xs={9} lg={4} className="p-1">
                                {ParseContent(entry)}
                            </Col>
                        </React.Fragment>
                    ))
                }
            </Row>
        </Card>);
}

function StrongLecture(props: { lecture: ILecture, token: string }) {
    return (
        <Row>
            <Col
                xs="12"
                sm="12"
                md="7"
                lg="6"
                className="pt-1 pb-0 py-sm-2">
                {ParseLectureTitle(props.lecture, props.token)}
            </Col>
        </Row>);
}

interface ILectureWithInfo {
    token: string
    type: string
    isCategoryDisabled: boolean
    lectureIndex: number
    scheduleIndex: number
    lecture: ILecture
    isEvenOrOdd: boolean
}

function NormalLectureWithCategory(props: ILectureWithInfo) {
    const deco = props.isEvenOrOdd ? "even" : "odd";
    const handleLink = (attachment: any) => {
        if (attachment.url !== undefined && attachment.url !== '') {
            return <>[<a
                className="text-nowrap"
                target="_blank"
                rel="noopener noreferrer"
                href={attachment.url}>
                {attachment.title}
            </a>]</>
        }
    }
    const handleVideo = (lecture: ILecture, attachment: any) => {
        if (attachment.videoId !== undefined) {
            return <VimeoVideoDialogue title={lecture.title}
                link={attachment.title}
                videoId={attachment.videoId} />
        } else if (attachment.otp !== undefined && attachment.playbackInfo !== undefined) {
            return <VdoCipherDialogue title={lecture.title}
                link={attachment.title}
                otp={attachment.otp}
                playbackInfo={attachment.playbackInfo} />
        }
    }
    return (
        <Row>
            <Col
                xs="12"
                sm="12"
                md="7"
                lg="8"
                className={deco + " pt-1 pb-0 py-sm-2"}>
                {ParseLectureTitle(props.lecture, props.token)}
            </Col>
            <Col
                xs="12"
                sm="12"
                md="5"
                lg="4"
                className="text-right text-md-left my-3 my-sm-2">
                {props.lecture.attachments && props.lecture.attachments.map((attachment, k) => (
                    <span
                        className="px-2"
                        key={"attachment-" + props.scheduleIndex + "-" + props.lectureIndex + "-" + k}>
                        {handleLink(attachment)}
                        {handleVideo(props.lecture, attachment)}
                    </span>
                ))}
            </Col>
        </Row>);
}

function NormalLectureWithoutCategory(props: ILectureWithInfo) {
    const deco = props.isEvenOrOdd ? "even" : "odd";
    return (
        <Row>
            <Col
                xs="12"
                sm="12"
                md="8"
                lg="8"
                className={deco + " pt-1 pb-0 py-sm-2"}>
                {ParseLectureTitle(props.lecture, props.token)}
            </Col>
            <Col
                xs="12"
                sm="12"
                md="4"
                lg="4"
                className="text-right text-md-left my-3 my-sm-2">
                {props.lecture.attachments && props.lecture.attachments.map((attachment, k) => (
                    <span
                        className="px-2"
                        key={"attachment-" + props.scheduleIndex + "-" + props.lectureIndex + "-" + k}>
                        [<a
                            className="text-nowrap"
                            target="_blank"
                            href={attachment.url}
                            rel="noopener noreferrer"
                        >
                            {attachment.title}
                        </a>]
                    </span>
                ))}
            </Col>
        </Row>);
}

function NormalLecture(props: ILectureWithInfo) {
    if (props.isCategoryDisabled) {
        return NormalLectureWithoutCategory(props)
    } else {
        return NormalLectureWithCategory(props)
    }
}

function MyLecture(props: ILectureWithInfo) {
    if (props.lecture.isHidden)
        return null;

    if (props.lecture.isStrong) {
        return <StrongLecture
            lecture={props.lecture}
            token={props.token}
        />
    } else {
        return <NormalLecture
            isEvenOrOdd={props.isEvenOrOdd}
            lecture={props.lecture}
            scheduleIndex={props.scheduleIndex}
            lectureIndex={props.lectureIndex}
            isCategoryDisabled={props.isCategoryDisabled}
            type={props.type}
            token={props.token}
        />
    }
}

interface IScheduleProps {
    token: string
    index: number
    entry: IScheduleEntry
    isCategoryDisabled: boolean
    type: string
}

function CollapsedLecture(props: ILectureWithInfo) {
    const deco = props.isEvenOrOdd ? "even" : "odd";
    return (
        <Row>
            <Col
                xs="12"
                sm="12"
                md="8"
                lg="8"
                className={deco + " pt-1 pb-0 py-sm-2"}>
                {ParseLectureTitle(props.lecture, props.token)}
            </Col>
            <Col
                xs="12"
                sm="12"
                md="4"
                lg="4"
                className="text-right text-md-left my-3 my-sm-2">
                {props.lecture.attachments && props.lecture.attachments.map((attachment, k) => (
                    <span
                        className="px-2"
                        key={"attachment-" + props.scheduleIndex + "-" + props.lectureIndex + "-" + k}>
                        [<a
                            className="text-nowrap"
                            target="_blank"
                            href={attachment.url}
                            rel="noopener noreferrer"
                        >
                            {attachment.title}
                        </a>]
                    </span>
                ))}
            </Col>
        </Row>);
}

function MySchedule(props: IScheduleProps) {
    if (props.type === "collapsed") {
        const entry = props.entry
        let k = 0
        return (
            <Accordion>
                <Card className="border-dark my-3" key={"schedule-" + props.index}>
                    <Accordion.Toggle as={Card.Header} id={entry.category} eventKey={k.toString()}>{entry.category}</Accordion.Toggle>
                    <Accordion.Collapse eventKey={k.toString()}>
                        <Card.Body>
                            <Row key={"schedule-row-" + props.index}>
                                <Col
                                    className="text-left">
                                    {
                                        entry.lectures.map(
                                            (lecture, j): JSX.Element => {
                                                k++;
                                                return <CollapsedLecture
                                                    key={'lecture-' + props.index + '-' + j}
                                                    isEvenOrOdd={k % 2 !== 0}
                                                    lecture={lecture}
                                                    lectureIndex={j}
                                                    scheduleIndex={props.index}
                                                    type={props.type}
                                                    isCategoryDisabled={props.isCategoryDisabled}
                                                    token={props.token}
                                                />;
                                            }
                                        )
                                    }
                                </Col>
                            </Row>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>)
    }
    else if (props.isCategoryDisabled) {
        const entry = props.entry
        let k = 0
        return (
            <Card className="border-dark my-3" key={"schedule-" + props.index}>
                <Row key={"schedule-row-" + props.index}>
                    <Col xs={{ span: 10, offset: 1 }} sm={{ span: 10, offset: 1 }} md={{ span: 11, offset: 1 }} lg={{ span: 11, offset: 1 }} className="text-left">
                        {
                            entry.lectures.map(
                                (lecture, j): JSX.Element => {
                                    if (lecture.isStrong) {
                                        k = 0;
                                    } else {
                                        k++;
                                    }
                                    return <MyLecture
                                        key={'lecture-' + props.index + '-' + j}
                                        isEvenOrOdd={k % 2 !== 0}
                                        lecture={lecture}
                                        lectureIndex={j}
                                        scheduleIndex={props.index}
                                        type={props.type}
                                        isCategoryDisabled={props.isCategoryDisabled}
                                        token={props.token}
                                    />;
                                }
                            )
                        }
                    </Col>
                </Row>
            </Card>)
    } else {
        const entry = props.entry
        let k = 0
        const id = entry.category!
        return (
            <Card className="border-dark my-3" key={"schedule-" + props.index}>
                <Row key={"schedule-row-" + props.index} id={id}>
                    {CreateCategory(entry.category!)}
                    <Col
                        xs={{ span: 10, offset: 1 }}
                        sm={{ span: 10, offset: 1 }}
                        md={{ span: 11, offset: 1 }}
                        lg={{ span: 10, offset: 0 }}
                        className="text-left">
                        {
                            entry.lectures.map(
                                (lecture, j) => {
                                    if (lecture.isStrong) {
                                        k = 0;
                                    } else {
                                        k++;
                                    }
                                    return <MyLecture
                                        key={'lecture-' + props.index + '-' + j}
                                        isEvenOrOdd={k % 2 !== 0}
                                        lecture={lecture}
                                        lectureIndex={j}
                                        scheduleIndex={props.index}
                                        isCategoryDisabled={props.isCategoryDisabled}
                                        type={props.type}
                                        token={props.token}
                                    />;
                                }
                            )
                        }
                    </Col>
                </Row>
            </Card>
        );
    }
}

interface ISchedulesProps {
    token: string
    isCategoryDisabled: boolean
    type: string
    schedule: IScheduleEntry[]
}

function MySchedules(props: ISchedulesProps) {
    return (
        <div>
            {
                props.schedule.map((entry, index) =>
                    <MySchedule
                        key={"schedule-" + index}
                        entry={entry}
                        index={index}
                        isCategoryDisabled={props.isCategoryDisabled}
                        type={props.type}
                        token={props.token}
                    />)
            }
        </div>)
}


interface IButtonProps {
    info?: IButtonInfo
}

function MyButton(props: IButtonProps) {
    if (props.info) {
        return (<Card className="mt-3">
            <Button
                variant="light"
                className="py-2" style={{ fontSize: "1.3em" }}
                href={props.info.link}>{props.info.msg}</Button>
        </Card>)
    }
    return null
}

interface IAlertProps {
    msg?: string;
}

function MyAlert(props: IAlertProps) {
    if (props.msg) {
        return (<Alert className="mt-2" variant="warning">
            <Alert.Heading>{props.msg}</Alert.Heading>
        </Alert>)
    }
    return null
}

function MySecrets(props: { token: string }) {
    const [data, setData] = useState<string>()
    const [isLoading, setLoading] = useState<boolean>(false)
    const { user } = useContext(AppContext)

    useEffect(() => {
        if (isLoading) return

        setLoading(true)

        const getData = async () => {
            if (props.token === null) return null

            const result = await firebase.apps[0]
                .firestore()
                .collection("courses")
                .doc(props.token)
                .collection('users')
                .doc(user?.email!)
                .get()
                .then(
                    (doc: any) => {
                        if (doc === undefined || doc.data() === undefined) {
                            return null
                        }
                        return doc.data()!.data
                    })
                .catch((err: any) => {
                    console.log(err)
                    return null
                })

            if (result !== null) {
                setData(result)
            }
        }
        getData().then(() => { })
    }, [props.token, data, isLoading, user])

    if (data) {
        return <>私有資訊： {data}</>
    } else {
        return <></>
    }
}

function MyCourse(props: RouteDataProps) {

    let { isCategoryDisabled, type } = props

    if (isCategoryDisabled === undefined) {
        isCategoryDisabled = false
    }
    if (type === undefined) {
        type = "normal"
    }

    useEffect(() => {
        if (props.anchor !== undefined) {
            const element =
                document.getElementById("/" + props.token + "#" + props.anchor)

            if (element == null) return;

            setTimeout(
                () => element.scrollIntoView(),
                100)
        }
    })

    return (
        <Container className="p-0">
            <Helmet>
                <title>{props.title + " | Feis Studio"}</title>
            </Helmet>
            <div className="border mt-3">
                <div className="d-flex flex-wrap flex-md-nowrap"
                    style={{
                        backgroundColor: "#f8f9fa",
                    }}>
                    <div>
                        <a href="/">
                            <img
                                src={props.logo}
                                height="108"
                                alt="Feis Studio logo"
                            />
                        </a>
                    </div>
                    <div className="align-self-center"
                        style={{
                            position: "relative",
                            marginLeft: "40px",
                            marginTop: "10px",
                            fontSize: "2.5em",
                        }}>
                        {props.title}
                    </div>
                </div>
                <MyNavbar
                    type={props.type}
                    token={props.token}
                    logo={props.logo}
                    title={props.title}
                    schedule={props.data.schedule}
                    isCategoryDisabled={isCategoryDisabled}
                />
            </div>
            <ExternalLinks />
            <MyButton info={props.data.button} />
            <MyAlert msg={props.data.alert} />
            <MyHeader
                header={props.data.header}
                type={props.type} />
            <MySchedules
                schedule={props.data.schedule}
                isCategoryDisabled={isCategoryDisabled}
                type={type}
                token={props.token}
            />
            <MySecrets token={props.token} />
        </Container>)
}

interface RouteDataProps {
    token: string
    anchor?: string
    logo: string
    title: string
    isCategoryDisabled?: boolean
    type?: string
    data: ICourseData
}

const Course = () => {
    let { token, anchor } = useParams<any>();

    if (token === undefined)
        return <Navigate to="/" />

    return (
        <RouteData token={token}>{(props: IRouteData) => (
            <MyCourse anchor={anchor} {...props} />
        )}
        </RouteData>
    )
}

export default Course